<template>
    <v-dialog :value="value" persistent max-width="800px">
        <v-card>
            <v-card-title>
                {{ $t('eav_property_enum.dialog_title') }}
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="3" v-for="(lang, index) in listLanguageNames" :key="index">
                        <TextInput :id="`value-${lang.id}`" :value="translations[lang.id].value" @input="onValueChange($event, lang.id)" :label="$t('eav_property_enum.value', {lang: lang.id})" />
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <Btn text @click="closeDialog">{{ $t('cancel') }}</Btn>
                <Btn @click="save">{{ $t('save') }}</Btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import TextInput from "../components/Form/TextInput";
import {mapGetters} from "vuex";
import Btn from "@/components/Form/Btn.vue";

export default {
    name: "EavPropertyListEnumForm",

    components: {
        Btn,
        TextInput
    },

    props: {
        value: {
            type: Object,
            required: false,
            default: () => null
        }
    },

    beforeMount() {

    },

    watch: {
        value: {
            immediate: true,
            handler() {
                this.resetTranslations();
            }
        }
    },

    data() {
        return {
            translations: {}
        };
    },

    computed: {
        ...mapGetters(['lang', 'listLanguageNames']),
    },

    methods: {
        resetTranslations() {
            let translations = {};
            if(this.value && this.value.translations) {
                translations = this.value.translations;
            }

            this.listLanguageNames.forEach((lang) => {
                if(!translations[lang.id]) {
                    translations[lang.id] = {
                        value: ""
                    };
                }
            })

            this.translations = translations;
        },

        onValueChange(value, lang)  {
            let translations = this.translations;

            translations[lang].value = value;
            this.$set(this, 'translations', translations);
        },

        closeDialog() {
            this.$emit('input', null);
        },

        save() {
            this.$emit('input', {
                id: this.value.id,
                index: this.value.index,
                translations: this.translations
            });
        }
    },
}
</script>
